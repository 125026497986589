<template>
    <b-form-select :class="field.class" :style="field.style" :disabled="state.disabled" size="sm"
                   :options="field.optionsfield ? data[field.optionsfield] : field.options"
                   v-model="data[field.name]"
                   v-on:input="$emit('input')"/>
</template>

<script>
export default {
    name: "EnumField",
    props: ['field', 'data', 'state'],
}
</script>

<style scoped>
</style>